@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .dropdown-input {
        @apply bg-gray-600 text-gray-50 truncate font-semibold relative w-full py-2 pl-3 pr-10 text-left cursor-pointer border border-gray-800 shadow-sm max-h-60 rounded-md text-base overflow-auto focus:outline-none sm:text-sm;
    }

    .dropdown-button {
        @apply relative w-full border border-gray-800 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1;
    }
}

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply text-gray-100;
    background: #141313;
}

label {
    @apply text-gray-700;
}

.bg-background {
    @apply bg-[#141313];
}

.canvas-container {
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
}

@media (min-width: 1024px) {
    .main-layout-right-side {
        max-width: calc(100% - 16rem);
    }
}

canvas {
    width: 100% !important;
    height: 100% !important;
}

@media print {
    .pagebreak {
        page-break-before: always;
    }

    body {
        @apply text-black;
        @apply bg-white;
    }
}

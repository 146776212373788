.root {
    @apply fixed inset-0 z-20 flex flex-col w-0 lg:w-12 transition-all;
    background-color: #141313;

    @media (max-width: 1024px) {
        &.isExpanded {
            @apply w-full;
        }
    }

    header {
        @apply flex flex-shrink-0 items-center h-16;

        .menuExpander {
            @apply flex items-center justify-start outline-none h-full w-full;
        }

        .header {
            @apply flex items-center justify-between w-full px-4 py-4;
            background-color: #141313;
        }
    }

    .logo svg {
        @apply w-full;
    }

    .nav {
        @apply space-y-1 flex flex-1 flex-col items-start;
    }

    .item-container {
        @apply flex flex-col w-full flex-shrink-0 relative px-2;
    }

    .navItem {
        @apply flex w-full flex-shrink-0 items-center justify-start h-10 cursor-pointer;

        &.active {
            @apply bg-red-600 text-white;
        }

        .icon-container {
            @apply flex flex-shrink-0 items-center justify-center text-white w-6 h-6 rounded-md hover:text-white hover:bg-red-900;
        }

        .icon {
            @apply flex-shrink-0 w-5 h-5;
        }

        .title {
            @apply ml-3 flex-1 text-left whitespace-nowrap text-sm leading-5 font-medium invisible;
        }

        &.disabled {
            @apply cursor-default pointer-events-none;

            .icon-container {
                @apply text-gray-400;
            }

            .title {
                @apply text-gray-400;
            }
        }
    }

    .navItemPanel {
        @apply space-y-1 z-10;
    }

    .subItem {
        @apply h-9 text-sm text-gray-400 hover:text-white;

        &.active {
            @apply bg-red-900 text-white hover:text-white hover:bg-red-700;
        }

        .title {
            @apply pl-8;
        }
    }

    // &.isExpanded {
    .nav {
        @apply overflow-y-auto;
    }

    .navItem {
        @apply rounded-md hover:bg-red-900 px-2;

        .icon-container {
            @apply flex items-center justify-center w-6 h-6 rounded-md hover:bg-red-900 hover:text-white;
        }

        .title {
            @apply whitespace-nowrap text-base leading-5 font-medium visible;
        }

        &:hover {
            .icon-container {
                @apply text-white bg-red-900;
            }

            .title {
                @apply text-white;
            }
        }
    }

    .navItemPanel {
        &.closed {
            display: none;
        }
    }
    // }

    @media (max-width: 1024px) {
        &:not(.isExpanded) {
            .nav {
                @apply hidden md:flex;
            }

            .info {
                @apply hidden;
            }

            .navItemPanel {
                @apply bg-gray-800 absolute -top-1 left-12 mt-0 rounded-r-md;
                display: none;

                .title {
                    @apply visible pl-5 pr-10 m-0;
                }
            }

            .item-container {
                &:hover {
                    > .navItemPanel {
                        @apply mt-0 p-1;
                        display: block;

                        .subItem {
                            @apply rounded-md;

                            &:hover {
                                @apply text-white bg-red-900;
                            }
                        }
                    }
                }
            }

            .subItem {
                .icon-container {
                    @apply rounded-md hover:bg-red-900;
                }
            }
        }
    }
}

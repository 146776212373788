.root {
    @apply w-full h-full p-8 flex justify-center items-center;

    &.xsmall {
        @apply p-0;
    }

    &.small {
        @apply p-2;
    }
}

.root {
    &.default {
        &.lighter {
            svg path {
                fill: theme('colors.gray.400');
            }
        }

        &:not(.lighter):not(.darker) {
            svg path {
                fill: theme('colors.gray.500');
            }
        }

        &.darker {
            svg path {
                fill: theme('colors.gray.600');
            }
        }
    }

    &.primary {
        &.lighter {
            svg path {
                fill: theme('colors.white');
            }
        }

        &:not(.lighter):not(.darker) {
            svg path {
                fill: theme('colors.white');
            }
        }

        &.darker {
            svg path {
                fill: theme('colors.gray.100');
            }
        }
    }

    &.secondary {
        &.lighter {
            svg path {
                fill: theme('colors.white');
            }
        }

        &:not(.lighter):not(.darker) {
            svg path {
                fill: theme('colors.white');
            }
        }

        &.darker {
            svg path {
                fill: theme('colors.gray.100');
            }
        }
    }

    &.tertiary {
        &.lighter {
            svg path {
                fill: theme('colors.blue.500');
            }
        }

        &:not(.lighter):not(.darker) {
            svg path {
                fill: theme('colors.blue.600');
            }
        }

        &.darker {
            svg path {
                fill: theme('colors.blue.700');
            }
        }
    }

    &.quaternary {
        &.lighter {
            svg path {
                fill: theme('colors.gray.400');
            }
        }

        &:not(.lighter):not(.darker) {
            svg path {
                fill: theme('colors.gray.500');
            }
        }

        &.darker {
            svg path {
                fill: theme('colors.gray.600');
            }
        }
    }

    &.danger {
        &.lighter {
            svg path {
                fill: theme('colors.red.400');
            }
        }

        &:not(.lighter):not(.darker) {
            svg path {
                fill: theme('colors.red.500');
            }
        }

        &.darker {
            svg path {
                fill: theme('colors.red.600');
            }
        }
    }
}

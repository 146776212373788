.tooltip {
    --tooltipBackground: rgba(75, 85, 99, var(--tw-bg-opacity));
    --tooltipBorder: #c0c0c0;
    --tooltipColor: #fff;
    @apply px-2 py-2 text-sm leading-4 whitespace-nowrap rounded-md border-0 shadow-none;
}

.tooltip[data-popper-placement*='top'] .arrow {
    width: 8px;
    height: 5px;
    margin-bottom: -5px;
}

.tooltip[data-popper-placement*='top'] .arrow::before {
    border-width: 5px 4px 0 4px;
}

.tooltip[data-popper-placement*='top'] .arrow::after {
    border-width: 5px 4px 0 4px;
}

.tooltip[data-popper-placement='left-start'] .arrow,
.tooltip[data-popper-placement='right-start'] .arrow {
    width: 5px;
    height: 8px;
}

.tooltip[data-popper-placement='left-start'] .arrow {
    margin-right: -5px;
}

.tooltip[data-popper-placement='right-start'] .arrow {
    margin-left: -5px;
}

.tooltip[data-popper-placement='left-start'] .arrow::after {
    left: 0;
    border-width: 4px 0 4px 5px;
}
.tooltip[data-popper-placement='left-start'] .arrow::before {
    content: none;
}

.tooltip[data-popper-placement='right-start'] .arrow::before,
.tooltip[data-popper-placement='right-start'] .arrow::before {
    border-width: 4px 0 4px 5px;
}

.root {
    @apply flex items-center px-4 py-2 text-sm leading-5 font-normal whitespace-nowrap;

    &:not(.disabled) {
        @apply cursor-pointer;
    }

    &.regular:not(.disabled) {
        @apply text-white hover:bg-gray-800;
    }

    &.custom:not(.disabled) {
        @apply text-white hover:bg-gray-800;
    }

    &.danger:not(.disabled) {
        @apply text-white hover:bg-gray-800;
    }

    &.disabled {
        @apply opacity-50 cursor-default hover:bg-transparent pointer-events-none;
    }
}

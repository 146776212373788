.root {
    @apply relative rounded-md;

    .input[type='text'] {
        @apply pl-3;
    }

    .input[type='text']:not(.disabled) {
        @apply cursor-pointer;
    }

    .calendarIcon {
        @apply absolute inset-y-0 left-2 pr-3 flex items-center cursor-pointer text-gray-400 pointer-events-none;
    }

    .chevronIcon {
        @apply absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-400 pointer-events-none;
    }

    &.active {
        .calendarIcon {
            @apply text-red-500;
        }
    }

    &.isRange {
        &.active {
            & input[type='text'] {
                @apply border-red-500 ring-1 ring-red-500;
            }
        }
    }

    &.hasError {
        .inputArea,
        .calendarIcon,
        .chevronIcon {
            @apply text-red-900;
        }

        .rootRange {
            .inputArea {
                .inputAreaInner {
                    &.active {
                        @apply border-red-400;
                    }

                    &.placeholder {
                        @apply text-red-300;
                    }
                }
            }
        }
    }

    .rootRange {
        @apply absolute flex top-0 bottom-0 left-9 right-0 cursor-pointer;

        .inputArea {
            @apply relative flex-1 flex items-center z-0 mr-7 h-full;

            .inputAreaInner {
                @apply w-full mr-2 text-sm placeholder-gray-400 cursor-pointer focus:outline-none;

                &.active {
                    @apply text-red-500;
                }

                &.disabled {
                    @apply bg-gray-100 cursor-default;
                }
            }

            &.active {
                &:after {
                    content: '';
                    height: 2px;
                    @apply absolute bottom-1 left-0 w-full bg-red-500;
                }
            }
        }

        .clearIcon {
            @apply absolute h-4 w-4 top-0 bottom-0 -right-5 m-auto hover:text-red-500 z-10 text-gray-400;
        }
    }

    &.disabled {
        @apply cursor-default;

        .input[type='text'],
        .calendarIcon,
        .chevronIcon,
        .rootRange {
            @apply cursor-default;
        }
    }
}

.root {
    @apply relative border rounded-md shadow-sm text-sm font-medium hover:bg-gray-50;

    &.focused {
        @apply outline-none ring-2 ring-offset-2;
    }

    &.outline {
        @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#141313] focus:ring-red-500;
    }

    &:not(.disabled) {
        @apply text-gray-700 border-gray-300;
    }

    &.disabled {
        @apply opacity-50 cursor-default hover:bg-transparent pointer-events-none;
    }

    .children {
        @apply inline-flex justify-center items-center;

        &--icon-left {
            @apply mr-2;
        }

        &--icon-right {
            @apply ml-2;
        }

        &--icon-left,
        &--icon-right {
            svg {
                @apply h-4;
            }
        }
    }

    &.loading {
        @apply bg-red-600 cursor-default;

        .children {
            @apply opacity-0;
        }

        .spinner {
            @apply absolute m-auto top-0 bottom-0 left-0 right-0;

            svg {
                @apply w-4 h-4;
            }
        }
    }

    &.default {
        @apply border-transparent text-white bg-red-600;

        &:not(.disabled):not(.loading) {
            @apply hover:bg-red-500;
        }

        &.disabled {
            @apply shadow-sm;
        }

        // &.hovered {
        //     @apply bg-gray-50;
        // }

        &.focused {
            @apply ring-red-600;
        }
    }

    &.primary {
        @apply inline-flex items-center justify-center border-transparent text-white bg-red-600;

        &:not(.disabled):not(.loading) {
            @apply hover:bg-red-700;
        }

        &.hovered {
            @apply bg-red-700;
        }

        &.focused {
            @apply ring-red-300;
        }
    }

    &.secondary {
        @apply inline-flex items-center justify-center font-normal border border-gray-600 text-white bg-gray-800 hover:bg-gray-700 focus:ring-red-500;

        &.hovered {
            @apply bg-gray-700;
        }

        &.focused {
            @apply ring-red-500;
        }
    }

    &.tertiary {
        @apply inline-flex items-center justify-center font-normal border-none text-white bg-gray-800 hover:bg-gray-700 focus:ring-red-500;

        &.hovered {
            @apply bg-gray-700;
        }

        &.focused {
            @apply ring-red-500;
        }
    }

    &.transparent {
        @apply inline-flex items-center justify-center border-transparent shadow-none text-gray-700 hover:bg-gray-200 focus:ring-gray-400;

        &.hovered {
            @apply bg-gray-200;
        }

        &.focused {
            @apply ring-gray-400;
        }
    }

    &.danger {
        @apply inline-flex items-center justify-center border-transparent bg-white font-medium text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700;

        &.hovered {
            @apply bg-red-200;
        }

        &.focused {
            @apply ring-red-300;
        }
    }

    &.link {
        @apply border-0 shadow-none text-blue-500 hover:bg-transparent hover:text-blue-600;

        &.hovered {
            @apply bg-blue-600;
        }

        &.focused {
            @apply ring-blue-300;
        }
    }

    &.sm {
        @apply px-2.5 py-1.5 text-xs font-normal;
        height: 1.875rem;

        .children {
            &--icon-left {
                @apply mr-2;
            }

            &--icon-right {
                @apply ml-2;
            }

            &--icon-left,
            &--icon-right {
                svg {
                    @apply w-4 h-4;
                }
            }
        }
    }

    &.md,
    &.lg {
        @apply px-4 py-2;
    }

    &.md {
        height: 2.375rem;
    }

    &.lg {
        height: 2.5rem;
    }

    &.xl {
        @apply px-6 py-3;

        & > * {
            @apply text-base leading-6 font-medium;
        }
    }
}
